import { IconProps } from 'assets/icons/Icon.spec'

function IconUser ({
  className
}: IconProps) {
  return (
    <svg className={className} viewBox="0 0 27 26" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 2C6.92487 2 2 6.92487 2 13C2 19.0751 6.92487 24 13 24C19.0751 24 24 19.0751 24 13C24 6.92487 19.0751 2 13 2ZM0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13C26 20.1797 20.1797 26 13 26C5.8203 26 0 20.1797 0 13Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 8C10.7909 8 9 9.79086 9 12C9 14.2091 10.7909 16 13 16C15.2091 16 17 14.2091 17 12C17 9.79086 15.2091 8 13 8ZM7 12C7 8.68629 9.68629 6 13 6C16.3137 6 19 8.68629 19 12C19 15.3137 16.3137 18 13 18C9.68629 18 7 15.3137 7 12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 18C11.5226 18 10.074 18.4084 8.81487 19.1812C7.55578 19.954 6.53528 21.0604 5.86649 22.3777C5.61648 22.8702 5.01459 23.0667 4.52213 22.8167C4.02968 22.5667 3.83314 21.9648 4.08316 21.4723C4.91914 19.8257 6.19477 18.4427 7.76863 17.4767C9.3425 16.5106 11.1533 16 13 16C14.8467 16 16.6572 16.5106 18.231 17.4767C19.8049 18.4427 21.0805 19.8257 21.9165 21.4723C22.1665 21.9648 21.97 22.5667 21.4775 22.8167C20.9851 23.0667 20.3832 22.8702 20.1332 22.3777C19.4644 21.0604 18.4439 19.954 17.1848 19.1812C15.9257 18.4084 14.4774 18 13 18Z"
      />
    </svg>
  )
}

export default IconUser
