import { memo } from 'react'

function AdaptiveApp () {
  return (
    <div>
      Adaptive App
    </div>
  )
}

export default memo(AdaptiveApp)