import { memo, useMemo } from 'react'
import css from './Stats.module.scss'
import useAnswers from 'hooks/firebase/useAnswers'
import IconSpinner from 'assets/icons/IconSpinner'
import Container from 'components/atoms/Container/Container'
import Filter from 'components/molecules/Filter/Filter'
import Heading from 'components/atoms/Heading/Heading'
import { AnswerItemType } from 'api/specs/answers.spec'
import {utils, writeFile} from 'xlsx'
import { Names } from 'utils/const'
import { MapMarkerCoordsType } from 'components/molecules/MapMarkerRequest/MapMarkerRequest.spec'

const downloadBlob = (content: string[], filename: string, contentType: string) => {
  // Create a blob
  const blob = new Blob(["\uFEFF" + content], {
    type: contentType + "; charset=utf-8;",
  });
  const url = URL.createObjectURL(blob);
  // Create a link to download it
  const a = document.createElement("a");
  a.href = url;
  a.setAttribute("download", filename);
  a.click();
};

function Stats () {
  const {data, isFetching} = useAnswers()

  const content = useMemo(() => {
    if (isFetching) return (
      <IconSpinner className={css.spinner} />
    )

    function handleClickButton (data: AnswerItemType[]) {
      const preparedData = data.map(({
        [Names.FAVOURITE_NIGHT]: night,
        [Names.FAVOURITE_DAY]: day,
        [Names.EVENING_LOCATION]: eveningLocation,
        ...item
      }) => {
        return {
          ...item,
          [Names.FAVOURITE_NIGHT]: night? (night as MapMarkerCoordsType[]).join(`, `) : ``,
          [Names.FAVOURITE_DAY]: night? (day as MapMarkerCoordsType[]).join(`, `) : ``,
          [Names.EVENING_LOCATION]: night? (eveningLocation as MapMarkerCoordsType[]).join(`, `) : ``,
        }
      })

      const worksheet = utils.json_to_sheet(preparedData)
      const workbook = utils.book_new()
      utils.book_append_sheet(workbook, worksheet, "Data")
      writeFile(workbook, "data.xlsx", { compression: true });
    }

    if (Array.isArray(data) && data.length > 0) {
      return (
        <>
          <Heading className={css.heading}>
            Просмотр результатов
            {Array.isArray(data) && data.length > 0 && (
              <button
                onClick={() => handleClickButton(data)}
                className={css.button}
                type="button"
              >
                {`(${data.length})`}
              </button>
            )}
          </Heading>
          <Filter
            data={data}
            className={css.filter}
          />
        </>
      )
    }

    return (
      <div className={css.empty}>
        На текущий момент результаты опроса отсутствуют
      </div>
    )
  }, [isFetching, data])

  return (
    <section>
      <Container className={css.wrapper}>
        {content}
      </Container>
    </section>
  )
}

export default memo(Stats)
