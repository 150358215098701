import { memo } from 'react'
import { IconProps } from 'assets/icons/Icon.spec'

function IconPinDelete ({
  className
}: IconProps) {
  return (
    <svg className={className} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.961 1.25c-6.167 0.346-11.114 5.134-11.707 11.199l-0.004 0.051c0 4.65 9.998 16.633 11.138 17.984 0.138 0.163 0.343 0.265 0.572 0.266h0.001c0 0 0.001 0 0.001 0 0.228 0 0.432-0.102 0.569-0.263l0.001-0.001c1.148-1.352 11.218-13.334 11.218-17.986-0.62-6.132-5.597-10.924-11.758-11.249l-0.031-0.001zM15.963 28.832c-3.31-4.014-10.213-13.060-10.213-16.332 0.601-5.283 4.875-9.398 10.178-9.748l0.033-0.002c5.361 0.332 9.666 4.451 10.284 9.698l0.005 0.052c0 3.271-6.955 12.32-10.287 16.332zM19.359 8.771c-0.136-0.136-0.324-0.22-0.531-0.22s-0.395 0.084-0.531 0.22v0l-2.297 2.297-2.298-2.297c-0.136-0.14-0.327-0.227-0.538-0.227-0.414 0-0.75 0.336-0.75 0.75 0 0.211 0.087 0.402 0.227 0.538l0 0 2.297 2.298-2.297 2.298c-0.141 0.136-0.228 0.327-0.228 0.538 0 0.414 0.336 0.75 0.75 0.75 0.211 0 0.402-0.087 0.538-0.228l2.298-2.297 2.297 2.297c0.136 0.136 0.324 0.22 0.531 0.22 0.415 0 0.751-0.336 0.751-0.751 0-0.207-0.084-0.395-0.219-0.53v0l-2.298-2.298 2.298-2.298c0.135-0.136 0.218-0.323 0.218-0.53s-0.083-0.394-0.218-0.53l0 0z" />
    </svg>
  )
}

export default memo(IconPinDelete)
